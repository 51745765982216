import { defineMessages, useIntl } from 'react-intl'
import { Flex, Text, Icon } from '@homebotapp/hb-react-component-catalog'
import { Listing, ListingCompact } from '../../../api/gqlaxy/gql/generated/graphql'

export const MSG = defineMessages({
  concession: {
    id: 'Listings.listing.concession',
    defaultMessage: 'Seller Concession'
  }
})

export interface SellerConcessionTagProps {
  listing: ListingCompact | Listing
}

export const SellerConcessionTag = ({ listing }: SellerConcessionTagProps) => {
  const intl = useIntl()
  return listing?.hasSellerConcession ? (
    <Flex
      alignItems='center'
      justifyItems='center'
      borderWidth={1}
      borderRadius={9}
      borderColor='neutral.500'
      backgroundColor='neutral.100'
      gap={2}
      px={2}
      py={1}
      ml={2}
    >
      <Text margin={0} fontWeight={600} size='2xs'>
        {intl.formatMessage(MSG.concession)}
      </Text>
    </Flex>
  ) : null
}
